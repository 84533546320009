// Entry point for the build script in your package.json
import '@hotwired/turbo-rails'
import './controllers'

document.addEventListener('turbo:click', function (event) {
    if (
        event.target.tagName === 'A' &&
        event.target.dataset.turboFrame === 'supplier_details'
    ) {
        history.pushState({}, '', event.target.href)
    }
})
