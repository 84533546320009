import { Controller } from "@hotwired/stimulus";
import { useIntersection } from 'stimulus-use';

export default class InfiniteScrollingController extends Controller {
    static values = { hasClicked: Boolean }; // Przechowuje informację o kliknięciu

    connect() {
        useIntersection(this, { threshold: 1 });
    }

    appear(entry) {
        if (!this.hasClickedValue) {
            this.element.click();
            this.hasClickedValue = true; // Ustaw flagę na true, aby nie klikać ponownie
        }
    }
}